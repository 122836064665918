import type { CalendarDate, TimeSlot } from "@/models/TimeSlot";
import gsap from "gsap";
import { ref } from "vue";

const isCalendarScrollSnapAlignActive = ref(false);

export function useDateTimeSelectorScrollManager() {
  const scrollToSelectedTimeSlots = (timeSlot: TimeSlot) => {
    const slotEl = document.getElementById(`slot-${timeSlot.time}`) as HTMLElement;

    const slotsScoller = document.getElementById("date-time-slot-selector");

    const offsetTop = slotEl?.offsetTop;

    if ((offsetTop !== null || offsetTop !== undefined) && slotsScoller) {
      gsap.to(slotsScoller as Element, { duration: 0.3, scrollTo: { y: offsetTop - 72 } });
    }
  };

  const scrollToMonth = (month: number, year: number) => {
    isCalendarScrollSnapAlignActive.value = false;
    const monthEl = document.getElementById(`month-${month}-${year}`) as HTMLElement;
    const calendarScoller = document.getElementById("date-picker-scrollable") as HTMLElement;

    const offsetLeft = monthEl?.offsetLeft;

    if ((offsetLeft !== null || offsetLeft !== undefined) && calendarScoller) {
      gsap.to(calendarScoller as Element, { duration: 0.3, scrollTo: { x: offsetLeft } });
    }
    setTimeout(() => (isCalendarScrollSnapAlignActive.value = true), 1000);
  };

  const scrollToSelectedDateTimeSlot = (date: CalendarDate, slot: TimeSlot) => {
    scrollToMonth(date.month as number, date.year as number);
    scrollToSelectedTimeSlots(slot);
  };

  return {
    isCalendarScrollSnapAlignActive,
    scrollToSelectedDateTimeSlot,
    scrollToMonth
  };
}
