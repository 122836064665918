<template lang="pug">
FeedbackView(:loading="loading" :showQrCode="showQr" :qrcode="order ? order.qrcode : ''" :secondContainer="showQr" @close-page="onClosePage" :image="getSaletypeImage(order?.saleType || Saletype.DELIVERY)")
  template(v-slot:first-container)
    div.text-h5.font-weight-medium.mb-6 {{ $t("payment.success.title") }}
    div(v-if="authenticated") 
      div.mx-10
        div.mb-6(v-if="confirmMessage") {{ confirmMessage }}
        div.pb-6 {{ $t("payment.success.checkOrderStatus") }}
      eat-btn.flex-grow-0.mt-4.mb-6.elevation-1.bg-primary(:to="{ name: 'user.orders' }") {{ $t("general.action.orderStatus") }}
    div(v-else)
      p.mx-10.pb-6 {{ $t("table.payment.success.loginOrRegister") }}
      div.d-flex
        eat-btn.flex-grow-1.mx-1.mt-4.mb-6.elevation-1.bg-primary(:to="{ name: 'auth.login' }") {{ $t("auth.login.login") }}
        eat-btn.flex-grow-1.mx-1.mt-4.mb-6.elevation-1.bg-primary(:to="{ name: 'auth.register' }") {{ $t("auth.register.createAccount") }}

  template(v-slot:above-qrcode)
    div.mt-6 {{ $t('payment.success.showQr') }}
</template>

<script setup lang="ts">
import FeedbackView from "@/layout/FeedbackView.vue";
import { setAppTitle } from "@/store/appTitle";
import { onMounted, ref } from "vue";
import { useCart } from "@/store/cart";
import { useApp } from "@/store/app";
import type { NullRef } from "@/utils";
import { get as getOrder, getUnauthenticated as getOrderUnauthenticated } from "@/api/Order";
import { initData } from "@/store/init/initData";
import type { Order } from "@/models/Order";
import { getQueryParam } from "@/utils/queryParams";
import { CartPrefix } from "@/models/Cart";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import type { Ref } from "vue";
import { Saletype } from "@/models/App";

const i18n = useI18n();
setAppTitle(i18n.t("payment.success.title").toString());

const router = useRouter();
const auth = inject('auth') as Ref<Auth>;
const authenticated = ref(auth.value.check());
const { app, getSaletypeImage } = useApp();
const loading = ref(false);
const order: NullRef<Order> = ref(null);
const showQr = ref(true);
const confirmMessage: NullRef<string> = ref(null);

const { cart, clearCart } = useCart();

onMounted(async () => {
  loading.value = true;
  const orderId = getQueryParam("orderId");

  if (!orderId) return router.replace({ name: "cart" });

  if (!auth.value.check()) {
    const userToken = getQueryParam("token");
    if (userToken) {
      auth.value.setUserToken(userToken);
      authenticated.value = true;
    }
  }

  if (cart.value?.prefix === CartPrefix.RESTAURANT || auth.value.check()) {
    order.value = await getOrder(orderId as string);
  } else {
    order.value = await getOrderUnauthenticated(orderId as string);
  }

  if (app.value && order.value) {
    showQr.value = app.value.services[order.value.saleType].showQrCode;
    confirmMessage.value = app.value.services[order.value.saleType].orderConfirmedMessage;
  }
  if (cart.value) clearCart();
  if (order.value) await initData(order.value.saleType, auth.value);
  loading.value = false;
});

const onClosePage = () => {
  router.replace({ name: "order" });
};

const host = location.host;
</script>
