<template lang="pug">
div#date-picker-scrollable.d-flex.rounded-xxl.bg-white(v-scrollable="true" :style="isCalendarScrollSnapAlignActive ? 'scroll-snap-type: x mandatory;' : ''")
  div.month.container.flex-shrink-0.d-flex.flex-column(v-for="month,  i in year" :key="i" :id="`month-${month.number}-${month.year}`")
    div.d-flex.align-center.mb-4
      button.navigation-button.next.pa-2.bg-white(type="button" v-if="i > 0" @click="onScrollToMonthClick(year[i - 1])")
        EatIcon(icon="$back")
      div.px-5(v-else)
      h5.month-name.text-h6.mx-auto.text-capitalize {{ month.name }}
      button.navigation-button.previous.pa-2.bg-white(type="button" v-if="i < year.length - 1" @click="onScrollToMonthClick(year[i + 1])")
        EatIcon(icon="$forward")
      div.px-5(v-else)
    div.dates-grid
      div.label.subtitle-2.mb-5(v-for="label, h in weekDaysLabels" :key="h") {{ label }}
      button.date__btn.d-flex.justify-center.mb-4(type="button" v-for="date, k in month.dates" :key="date.id" @click.stop="onDateClick(date)") 
        div.date__number.mb-1.rounded-circle.text-h6.font-weight-regular(v-if="!date.isPadding" :class="isSelected(date) ? 'bg-selected' : ''") 
          div(:class="{ 'text-muted': date.isPast }") {{ date.day }}
          div.red-dot.bg-error.rounded-circle(v-if="date.isClosed")
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  useCalendar
} from "@/store/timeSlots/calendar";
import { useDateTimeSelectorScrollManager } from "@/store/timeSlots/scrollManager";
import type { PropType } from "vue";
import { computed } from "vue";
import type { Calendar, CalendarDate, CalendarMonth } from "@/models/TimeSlot";

const props = defineProps({
  modelValue: {
    type: Object as PropType<CalendarDate>
  },
  reservation: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);
const selectedDate = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const { calendar, reserveCalendar, weekDaysLabels, isSameDate } = useCalendar();
const { isCalendarScrollSnapAlignActive, scrollToMonth } = useDateTimeSelectorScrollManager();

const year = ref<Calendar>([]);

const isSelected = (date: CalendarDate) => {
  return selectedDate.value
    ? isSameDate(selectedDate.value, date)
    : false;
};

onMounted(() => {
  year.value = props.reservation ? reserveCalendar.value : calendar.value;
});

const onScrollToMonthClick = (month: CalendarMonth) => {
  scrollToMonth(month.number, month.year);
};

const onDateClick = (date: CalendarDate) => {
  if (date.isPast || date.isPadding) return;
  selectedDate.value = date;
};
</script>

<style lang="scss" scoped>
#date-picker-scrollable {
  height: 100%;
  max-width: 100%;
  .month {
    scroll-snap-align: center;
    width: 100%;
    height: 100%;
    .month-name {
      text-transform: capitalize;
      text-align: start;
    }
    .dates-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      .date__btn,
      .label {
        text-align: center;
        position: relative;
        .date__number {
          width: 44px;
          height: 44px;
          display: grid;
          place-items: center;
        }
        .red-dot {
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 5px;
          height: 5px;
        }
      }
    }
  }
}
</style>
