<template lang="pug">
FeedbackView(:loading="loading" backgroundClass="bg-error" @close-page="onClosePage" :image="getSaletypeImage(cart?.saletype || Saletype.DELIVERY)")
  template(v-slot:first-container)
    div.text-h5.font-weight-medium.mt-6 {{ $t('payment.error.title') }}
    div.mt-6(v-if="reason === 'fidelityCard'") {{ $t('payment.error.notEnoughMoney') }}
    div.mt-10.order-error__buttons(v-if="authenticated")
      eat-btn.mt-4.bg-primary(@click="retryPayment" v-if="order && order.paymentMethod != PaymentMethod.FIDELITY_CARD && retryPaymentURL") {{$t('payment.error.retryPayment')}}
    eat-btn.mt-4.mb-6.bg-white(:to="{ name: 'cart' }") {{$t('payment.error.backToCart')}}
</template>

<script setup lang="ts">
import FeedbackView from "@/layout/FeedbackView.vue";
import { setAppTitle } from "@/store/appTitle";
import { onMounted, onUnmounted, ref } from "vue";
import { useCart } from "@/store/cart";
import {
  delete_,
  get as getOrder,
  getPostePaymentURL,
  getUnauthenticated as getOrderUnauthenticated
} from "@/api/Order";
import { PaymentMethod, Saletype } from "@/models/App";
import { useApp } from "@/store/app";
import type { NullRef } from "@/utils";
import { initData } from "@/store/init/initData";
import { CartPrefix } from "@/models/Cart";
import { getQueryParam } from "@/utils/queryParams";
import { useRestoreOrderCart } from "@/store/orderHistory/restoreOrderCart";
import { useMenu } from "@/store/menu";
import type { Order } from "@/models/Order";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import type { Ref } from "vue";

const i18n = useI18n();
setAppTitle(i18n.t("payment.error.title").toString());

const { app, getSaletypeImage } = useApp();
const route = useRoute();
const router = useRouter();
const auth = inject('auth') as Ref<Auth>;

const loading = ref(true);
const authenticated = ref(auth.value.check());
const order: NullRef<Order> = ref(null);
const retryPaymentURL: NullRef<string> = ref(null);

const { restoreCartItemsFromOrderItems } = useRestoreOrderCart();
const { cart, clearCart, cartPrefix, initCart } = useCart();
const reason: NullRef<string> = ref(null);

const restoreSession = async () => {
  const items = order.value?.orderItems;
  const saletype = order.value?.saleType;
  const { categories } = useMenu(saletype);

  if (items && saletype && cartPrefix.value) {
    clearCart();
    initCart({ saletype, prefix: cartPrefix.value });
    await initData(saletype, auth.value);

    if (categories.value) {
      restoreCartItemsFromOrderItems(items, categories.value);
    }
  }
};

const showQr = ref(false);
onMounted(async () => {
  const orderId = getQueryParam("orderId");
  if (!orderId) router.replace({ name: "cart" });

  if (!auth.value.check()) {
    const userToken = getQueryParam("token");
    if (userToken) auth.value.setUserToken(userToken);
    authenticated.value = true;
  }
  restoreSession();

  if (auth.value.check() || cart.value?.prefix === CartPrefix.RESTAURANT) {
    order.value = await getOrder(orderId as string);
    if (order.value?.paymentMethod == PaymentMethod.POSTE_VIRTUAL_POS) {
      retryPaymentURL.value = (await getPostePaymentURL(order.value.id)).paymentUrl;
    }
  } else {
    order.value = await getOrderUnauthenticated(orderId as string);
  }

  if (app.value && order.value) {
    showQr.value = app.value.services[order.value.saleType].showQrCode;
  }

  reason.value = route.query.reason as string | null;
  loading.value = false;
});

const deleteOrder = async () => {
  if (!order.value) return;
  loading.value = true;
  try {
    await delete_(order.value.id);
  } finally {
    loading.value = false;
  }
};

onUnmounted(async () => {
  /* if (auth.value.check() && order.value?.id) {
    await deleteOrder();
  } */
});

const retryPayment = async () => {
  if (!order.value) return;
  if (!retryPaymentURL.value) return;
  window.location.assign(retryPaymentURL.value);
};

const onClosePage = async () => {
  router.replace({ name: "order" });
};
</script>

<style lang="scss" scoped>
.order-error__buttons {
  width: 100%;
}
</style>
