<template lang="pug">
EatView.bg-neutral-a(v-if="cart")
  div.cart-container.bg-neutral-a.pb-15(v-if="cart && cart.items.length" v-scrollable="true")
    div.cross-selling.d-flex(v-if="crossSellingCategories.length && app?.services[cart.saletype].crossSelling")
      div.px-4.py-3.bg-primary.d-flex.align-center.flex-grow-1
        EatImage.flex-shrink-0.rounded-sm(v-if="app.services[cart.saletype].crossSelling?.image" :src="app.services[cart.saletype].crossSelling?.image" :height="76" aspectRatio="1/1")
        div.flex-grow-1.px-4 {{ app.services[cart.saletype].crossSelling?.message }}
        EatBtn.bg-white.flex-grow-0(:to="{ name: 'cart.crossSelling' }")  {{ $t('cart.crossSelling.go') }}

    CartRenderer.mt-1.mb-6

  div.empty-cart__container.flex-grow-1.bg-neutral-a.d-flex.flex-column.overflow-hidden(v-else)
      div.empty-cart__message.flex-grow-0.bg-primary.text-center.px-9.py-3 {{ $t("cart.letsOrder") }}
      div.flex-grow-1(v-scrollable="true")
        RoundedContainer.my-1
          div.flex-grow-1.d-flex.align-center
            EatImage.empty-cart__image.rounded-circle.mt-10.mx-auto(:src="getSaletypeImage(cart.saletype || Saletype.DELIVERY)")
          div.mt-7.mb-15.text-center {{ $t("cart.empty") }} 

  div.cart-bottom-bar.border-t(v-if="cart && cart.items.length")
    div.pa-3.bg-white.d-flex.align-center
      EatFloatBtn(@click="onContinueClick") {{ $t("cart.action.goToCheckout") }}
      div.total-section.ms-auto
        div.text-h6.font-weight-regular {{ $t("cart.total", { price: formatPrice(cart.total) }) }}

  NetworkResponseDialog

  template(v-slot:float-button)
    div(v-if="!cart || !cart.items.length")
      EatFloatBtn(:to="{ name: 'order' }" icon="$order") {{ $t("cart.action.floatBtn") }}
</template>

<script setup lang="ts">
import NetworkResponseDialog from "@/layout/partials/NetworkResponseDialog.vue";
import CartRenderer from "@/views/cart/components/CartRenderer.vue";
import { nItems } from "@/models/Cart";
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { formatPrice } from "@/utils/formatPrice";
import { computed, watch } from "vue";
import { useCart } from "@/store/cart";
import { useCrossSelling } from "@/store/cart/crossSelling";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { Saletype } from "@/models/App";

const i18n = useI18n();
const { app, getSaletypeImage } = useApp();
const { cart } = useCart();

const itemsInCart = computed(() => {
  return cart.value ? nItems(cart.value) : 0;
});

watch(
  itemsInCart,
  value => {
    setAppTitle(
      value ? i18n.t("cart.title", { items: value }).toString() : i18n.t("cart.cart").toString()
    );
  },
  { immediate: true }
);

const router = useRouter();
const onContinueClick = () => {
  router.push({ name: "cart.checkout" });
};

const { crossSellingCategories } = useCrossSelling();
</script>

<style lang="scss" scoped>
.cart-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.cart-container {
  height: 100%;
}
.empty-cart__container {
  height: 100%;
  .empty-cart__image {
    height: 200px;
    width: 200px;
  }
}

.cross-selling {
  position: sticky;
  top: 0;
  z-index: 2;
  min-height: 64px;
  .cross-selling__btn {
    max-width: max-content;
  }
}
</style>
