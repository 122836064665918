<template lang="pug">
div.button-group__container.d-flex.border.rounded-sm
    button.button-group__btn.rounded-sm.flex-grow-1.px-4(
        v-for="option, i in options" :key="i" 
        :class="{ 'bg-primary' : localValue === option.value }"
        :style="`max-width: ${100/options.length}%`"
        @click="localValue = option.value"
    ) 
      div.text.text-body-2.font-weight-medium.text-truncate {{ $t(option.label) }}
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';

const props = defineProps({
    options: {
        type: Array as PropType<{ value: string, label: string }[]>,
        required: true
    },
    modelValue: String
});
const emit = defineEmits(['update:modelValue']);
const localValue = computed({
    get: () => props.modelValue,
    set: (value: string | undefined) => emit('update:modelValue', value)
});
</script>

<style lang="scss" scoped>
.button-group__container {
  background-color: var(--clr-controls);
  width: 100%;
  .button-group__btn {
    position: relative;
    min-height: 48px;
    overflow: hidden;
    .text {
      position: relative;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>