<template lang="pug">
div.d-flex.flex-column
  button.date-time-slot__button.pt-2.px-4.d-flex.align-center.border.rounded-sm(type="button" @click="onOpenDialog" :class="{ 'text-field__error': errored }")
    EatIcon.text-primary.flex-shrink-0(icon="$calendar")
    div.px-4.text-left
      div.font-weight-medium.text-capitalize {{ pickupDate }}
      div.text-body-2(:class="{ 'text-error': !pickupTime }") {{ pickupTime ? pickupTime : $t("dateTimeSlot.noAvailabilityToday") }}
    div.d-flex.align-center.ms-auto
      EatIcon(icon="$chevronDown" size="18px")

  FullPageDialog(v-model="showDialog" :scrollable="false" teleportTarget="#date-time-slot-dialog")
    template(v-slot:header)

      div.d-flex.flex-column(style="width: 100%")
        div.d-flex.align-center.pa-2.bg-white
          button(@click="onCloseDialog")
            EatIcon(icon="$close" size="16").ms-3.me-5
          div.border.ms-auto.me-2.rounded-sm
            div.text-capitalize.py-1.px-7.bg-gcontrols.font-weight-medium.rounded-sm {{ reservation ? $t("dateTimeSlot.reservation") : cart ? cart.saletype : '' }}
        div.gradient-border-bottom

    template(v-slot:main)

      div.flex-grow-1(v-scrollable="true")
        div#date-time-slot-selector.flex-grow-1.bg-neutral-a.py-2.d-flex.flex-column(v-scrollable="true"  v-if="!overloadedCategories.length")
          EatField.w-100.flex-shrink-0(name="date" v-slot="{ value, handleChange }")
            DatePicker(:modelValue="value" @update:modelValue="handleChange" :reservation="reservation")
          EatField.w-100.flex-shrink-0.flex-grow-1(name="timeSlot" v-slot="{ value, handleChange }" v-if="availableSlots.length || isLoading")
            TimeSlotPicker.h-100(:modelValue="value" @update:modelValue="handleChange" :timeSlots="availableSlots" :reservation="reservation")
          RoundedContainer.pb-15.flex-shrink-0.flex-grow-1(v-else) 
            transition(name="fade")
              div.d-flex.flex-column.align-center.justify-center.pt-10
                div.time-slot__error-image.mb-8
                  EatImage.rounded-circle.logo(:src="location?.logoUrl")
                div.time-slot__error-message.text-error {{ $t("dateTimeSlot.messages.serviceNotActive") }}

        div.overloaded-categories__container.container.d-flex.flex-column.align-center.justify-center.flex-grow-1(v-else)
          div.overloaded-categories__image.mb-8
            EatImage.rounded-circle.logo(:src="location ? location.logoUrl : ''")
          div.overloaded-categories__message.text-center
            div {{ $t("dateTimeSlot.overloadedCategories") }}
            div.my-6.font-weight-medium {{ overloadedCategories }}
            div {{ $t("dateTimeSlot.contactStaff") }}

    template(v-slot:footer)

      EatCta(v-if="availableSlots.length && !overloadedCategories.length")
        EatCtaButton(type="button" :primary="true" icon="$calendar" @click="onSubmit") {{ $t("dateTimeSlot.confirm") }} 
</template>

<script setup lang="ts">
import { computed, onMounted, type PropType, ref, nextTick, watch } from "vue";
import FullPageDialog from "@/layout/partials/FullPageDialog.vue";
import DatePicker from "@/components/dateTimeSlots/DatePicker.vue";
import TimeSlotPicker from "@/components/dateTimeSlots/TimeSlotPicker.vue";
import { useCart } from "@/store/cart";
import { DateTime } from "luxon";
import { useI18n } from "vue-i18n";
import { useDateTimeSlots, type DateTimeSlot } from "@/store/timeSlots/dateTimeSlots";
import { useTimeSlots } from "@/store/timeSlots/timeSlots";
import { useApp } from "@/store/app";
import { useCartItems } from "@/store/cart/cartItems";
import { useMenu } from "@/store/menu";
import { useForm, useFieldValue } from "vee-validate";
import { object } from "yup";
import { useDateTimeSelectorScrollManager } from "@/store/timeSlots/scrollManager";
import type { TimeSlot } from "@/models";
import type { CalendarDate } from "@/models/TimeSlot";

const i18n = useI18n();
const { location } = useApp();
const { cart } = useCart();
const { availableSlots } = useTimeSlots();
const { getOverloadedCategories } = useCartItems();
const { categories, fetchMenu } = useMenu(cart.value?.saletype);
const { getFirstAvailableSlot } = useDateTimeSlots();
const { getOrderSlots, getReservationSlots } = useTimeSlots();
const { scrollToSelectedDateTimeSlot } = useDateTimeSelectorScrollManager();

const props = defineProps({
  modelValue: {
    type: Object as PropType<DateTimeSlot>,
  },
  reservation: {
    type: Boolean,
    default: false
  },
  errored: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "close", "update"]);
const dateTimeSlot = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value) 
});

const { setFieldValue, handleSubmit } = useForm({
  validationSchema: {
    date: object().required(),
    timeSlot: object().required()
  }
});

const date = useFieldValue<CalendarDate>('date');
const timeSlot = useFieldValue<TimeSlot>('timeSlot');

const showDialog = ref(false);
const isLoading = ref(false);

watch(date, async (date, oldDate) => {
  if (!date || date === oldDate) return;

  try {
    isLoading.value = true;
    availableSlots.value = props.reservation
      ? await getReservationSlots(date)
      : await getOrderSlots(date);
    if(date.id === dateTimeSlot.value?.date.id && dateTimeSlot.value?.timeSlot) {
      setFieldValue('timeSlot', dateTimeSlot.value?.timeSlot);
    } else {
      const availableSlot = getFirstAvailableSlot(availableSlots.value);
      setFieldValue('timeSlot', availableSlot);
    }
    if(showDialog.value){
      nextTick(() => {
        if (date && timeSlot.value) {
          scrollToSelectedDateTimeSlot(date, timeSlot.value);
        }
      });
    }
  } catch(e) {
    availableSlots.value = [];
  } finally {
    isLoading.value = false;
  }

}, { immediate: true });

onMounted(async () => { if(!props.reservation) fetchMenu() });
const overloadedCategories = computed(() => {
  const overloadedCategories = getOverloadedCategories(categories.value);
  return overloadedCategories.map(category => category.name).join(", ");
});

const pickupDate = computed(() => {
  if(!dateTimeSlot.value?.date) return ''; 
  const { day, month, year } = dateTimeSlot.value?.date;
  return DateTime.fromObject({ day, month, year }).toFormat("cccc d LLLL", {
    locale: i18n.locale.value
  });
});

const pickupTime = computed(() => dateTimeSlot.value?.timeSlot?.time || null);

const onOpenDialog = () => {
  setFieldValue('date', dateTimeSlot.value?.date);
  setFieldValue('timeSlot', dateTimeSlot.value?.timeSlot);
  showDialog.value = true;
}

const onCloseDialog = () => {
  showDialog.value = false;
  setFieldValue('date', undefined);
  setFieldValue('timeSlot', undefined);
};

const onSubmit = handleSubmit(
  (values) => {
    dateTimeSlot.value = { date: values.date, timeSlot: values.timeSlot } as DateTimeSlot
    onCloseDialog();
  }
);
</script>

<style lang="scss" scoped>
.date-time-slot__button {
  min-height: 80px;
  position: relative;
  &.text-field__error::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--clr-error);
  }
}

.overloaded-categories__container {
  .overloaded-categories__image {
    width: 117px;
    height: 117px;
    .logo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .overloaded-categories__message {
    max-width: 35ch;
    text-align: center;
  }
}
.gradient-border-bottom {
  height: 2px;
  background: linear-gradient(226deg, rgba(197, 245, 49, 1) 10%, rgba(0, 113, 242, 1) 92%);
}

#date-time-slot-selector {
  display: grid;
  gap: 8px;
  grid-template-rows: max-content max-content;
  height: 100%;
}


.time-slot__error-image {
  width: 117px;
  height: 117px;
  .logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.time-slot__error-message {
  text-align: center;
}
</style>
