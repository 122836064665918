<template lang="pug">
RoundedContainer(:horizontal-padding="false")
    CartProductsList
    EatUl.border-t(v-if="cart")
        EatLi.d-flex(v-if="cart.saletype == Saletype.DELIVERY && cartDeliveryMinPurchase && cart.subTotal < cartDeliveryMinPurchase")
            div.me-auto {{ $t("cart.minPurchase") }}
            div {{ formatPrice(cartDeliveryMinPurchase) }}

        EatLi.d-flex.px-2
            div.me-auto {{ $t("cart.order") }}
            div.font-weight-bold {{ formatPrice(cart.subTotal) }}

        EatLi(v-if="cart && cart.subtotalVariation && cart.subtotalVariation.promoCode")
            div.d-flex.align-center
                div.me-auto {{ cart.subtotalVariation.promoCode.code }}
                SubTotalPrice(
                  :isPercentage="cart.subtotalVariation.isPercentage"
                  :variation="cart.subtotalVariation.variation", 
                  :priceVariation="cart.subtotalVariation.priceVariation" 
                )
        EatLi(v-if="cart.affiliationDiscount")
            div.d-flex.align-center
                div.me-auto.text-uppercase {{ cart.affiliationDiscount.name }}
                Price(:price="cart.affiliationDiscount.price")

        EatLi.d-flex(v-if="cart && cart.saletype == Saletype.DELIVERY && cart.deliveryPrice")
            div.me-auto {{ $t('general.deliveryPrice') }}
            div(v-if="cart.deliveryPrice.isPercentage") {{ cart.deliveryPrice.price }}%
            div(v-if="!cart.deliveryPrice.isPercentage") + {{ formatPrice(cart.deliveryPrice.price) }} 
</template>

<script setup lang="ts">
import { useApp } from "@/store/app";
import SubTotalPrice from "@/components/utils/SubTotalPrice.vue";
import Price from "@/components/utils/Price.vue";
import CartProductsList from "@/views/cart/components/CartProductsList.vue";
import { formatPrice } from "@/utils/formatPrice";
import { computed } from "vue";
import { Saletype } from "@/models/App";
import { useCart } from "@/store/cart";

const { deliveryService } = useApp();
const { cart } = useCart();

const cartDeliveryMinPurchase = computed(() => {
  return cart.value?.deliveryPrice?.minPurchase || deliveryService.value?.minPurchase;
});
</script>
