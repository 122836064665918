<template lang="pug">
teleport(to="#network-response-dialog")
  transition(name="grow")
    EatDialog(v-model="showDialog")
      component.absolute-blr.max-h-100.bg-white.rounded-t-xxl.d-flex.flex-column.overflow-hidden.elevation-5(:is="responseType")
</template>

<script setup lang="ts">
import EatDialog from "@/components/modals/EatDialog.vue";
import useNetworkResponseDialog from "@/store/layout/networkResponseDialog";

const { showDialog, responseType } = useNetworkResponseDialog();
</script>

<style lang="scss" scoped>
.grow-from-point-enter-active,
.grow-from-point-leave-active {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.grow-from-point-enter,
.grow-from-point-leave-to {
  transform: scale(0);
}

.grow-enter-active,
.grow-leave-active,
.shrink-on-leave-leave-active {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.grow-enter,
.grow-leave-to,
.shrink-on-leave-leave-to {
  transform: translateY(100%);
}
</style>
