import { getMembershipDiscount } from "@/api/Order";
import { useSendOrder } from "./sendOrder";
import { useCart } from "../cart";
import { TypeSnackbar, setSnackbar } from "../layout/snackbar";
import type { APIError } from "@/api/API";
import { useUser } from "../user";

export function useMembershipDiscount() {
  const applyMembershipDiscountToCart = async (values: any) => {
    const { getApplyDiscountParams } = useSendOrder();
    const { setCartSubtotalVariation, setCartMembershipDiscount, setCartPromoCodes } = useCart();
    const { user } = useUser();
    const applyDiscountParams = getApplyDiscountParams(values);
    if (!applyDiscountParams) return;

    try {
      const order = await getMembershipDiscount(applyDiscountParams);
      const discountItem = {
        price: order.discountItem.price,
        name: user.value?.fidelityCard?.affiliation || "Membership"
      };
      setCartSubtotalVariation(null);
      setCartMembershipDiscount(discountItem);
      setCartPromoCodes(order.promoCodes);
    } catch (e) {
      const error = e as APIError;
      if (error?.response?.status === 403) {
        setSnackbar("Codice Promo: " + error.response.data.message, TypeSnackbar.ERROR);
        throw error;
      } else {
        throw error;
      }
    }
  };

  return {
    applyMembershipDiscountToCart
  };
}
