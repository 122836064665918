import type { APIError } from "@/api/API";
import { addPromoCodeToOrder, addPromoCodeToOrderUnauthenticated } from "@/api/Order";
import type { CartPriceVariation, ItemPriceVariation } from "@/models/PriceVariation";
import { setSnackbar, TypeSnackbar } from "../layout/snackbar";
import { useSendOrder } from "./sendOrder";
import { useCartItems } from "./cartItems";

export function usePromoCode() {
  const { addPromoCode } = useCartItems();

  const applyPromoCodeToCart = async (values: any, code: string, authenticated: boolean) => {
    const { getApplyDiscountParams } = useSendOrder();
    const applyPromoCodeParams = getApplyDiscountParams(values);
    if (!applyPromoCodeParams) return;

    let orderResponse;
    try {
      orderResponse = authenticated
        ? await addPromoCodeToOrder(applyPromoCodeParams, code)
        : await addPromoCodeToOrderUnauthenticated(applyPromoCodeParams, code);

      const promoCodes = orderResponse.promoCodes;
      const itemVariations: ItemPriceVariation[] = orderResponse.orderItems
        .filter(item => item.finalUnitaryPriceVariation != null && !item.isSubtotal)
        .map(item => ({
          productId: item.productId,
          isPercentage: item.finalUnitaryPriceVariation!.isPercentage,
          roundValue: item.finalUnitaryPriceVariation!.roundValue,
          variation: item.finalUnitaryPriceVariation!.variation,
          unitaryPrice: item.unitaryPrice,
          price: item.price,
          priceVariation: item.priceVariation,
          finalPrice: item.finalPrice,
          promoCode: item.finalUnitaryPriceVariation?.promoCode
        }));

      let subTotalVariation: CartPriceVariation | null = null;
      const subtotal = orderResponse.subtotalItem;
      if (subtotal) {
        subTotalVariation = {
          isPercentage: subtotal.finalUnitaryPriceVariation?.isPercentage || false,
          roundValue: subtotal.finalUnitaryPriceVariation?.roundValue || 0,
          variation: subtotal.finalUnitaryPriceVariation?.variation || 0,
          unitaryPrice: subtotal.unitaryPrice || 0,
          price: subtotal.price || 0,
          priceVariation: subtotal.priceVariation || 0,
          finalPrice: subtotal.finalPrice || 0,
          promoCode: subtotal.finalUnitaryPriceVariation?.promoCode
        };
      }

      addPromoCode(promoCodes, itemVariations, subTotalVariation);
    } catch (e) {
      const error = e as APIError;
      if (error?.response?.status === 403) {
        setSnackbar("Codice Promo: " + error.response.data.message, TypeSnackbar.ERROR);
        throw error;
      } else {
        throw error;
      }
    }
  };

  return {
    applyPromoCodeToCart
  };
}
