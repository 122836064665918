<template lang="pug">
RoundedContainer(:title="$t(title)")
  template(v-slot:top-right)
    div.no-slots-available.flex-shrink-1.text-error.text-caption(v-if="!areThereEmptySlots") {{ $t("dateTimeSlot.unavailable") }}  
    div.no-slots-available.flex-shrink-1.text-primary.text-caption(v-else) {{ $t("dateTimeSlot.available") }}
  template(v-slot:default)
    div.time-slot-picker(v-if="timeSlots.length" :class="{ 'time-slot-picker--from-to': fromToTimeSlots, 'slot-selected': !!timeSlots.length }")
      div.time-slot__container.pb-3(v-for="timeSlot, i in timeSlots" :key="i")
        button.time-slot__pill.rounded-pill.px-4.py-2(type="button" :class="!timeSlot.isFull && isSelected(timeSlot) ? 'bg-selected' : ''" @click.stop="onTimeSlotClick(timeSlot)")
          div.time-slot__text.text-h6.font-weight-regular(:class="{'text-muted': timeSlot.isFull || timeSlot.isPast }" :id="`slot-${timeSlot.time}`") {{ timeSlot.time }}
    Loading.text-primary.my-15(v-else)
</template>

<script setup lang="ts">
import { Saletype } from "@/models/App";
import type { TimeSlot } from "@/models/TimeSlot";
import { useApp } from "@/store/app";
import { useCart } from "@/store/cart";
import { computed, onMounted, ref } from "vue";
import type { PropType } from "vue";

const props = defineProps({
  modelValue: {
    type: Object as PropType<TimeSlot|null>,
    default: null
  },
  timeSlots: {
    type: Array as PropType<TimeSlot[]>,
    required: true
  },
  reservation: {
    type: Boolean,
    required: true
  }
});

const { cart } = useCart();
const { getFromToTimeSlots } = useApp();

const emit = defineEmits(['update:modelValue']);
const currentSelectedTimeSlot = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
});

const title = computed(() => {
  if (props.reservation) {
    return "dateTimeSlot.time";
  } else {
    return cart.value?.saletype === Saletype.TAKEAWAY
      ? "dateTimeSlot.pickUpTime"
      : "dateTimeSlot.deliveryTime";
  }
});

const fromToTimeSlots = ref(false);
onMounted(async () => {
  fromToTimeSlots.value = props.reservation
    ? getFromToTimeSlots(Saletype.RESERVATION)
    : (!!cart.value && getFromToTimeSlots(cart.value.saletype));
});

const areThereEmptySlots = computed(() => {
  return !!props.timeSlots.filter(slot => !slot.isFull).length;
});

const onTimeSlotClick = (slot: TimeSlot) => {
  if (slot.isFull || slot.isPast) return;
  currentSelectedTimeSlot.value = slot;
};

const isSelected = (timeSlot: TimeSlot) => {
  return currentSelectedTimeSlot.value?.time === timeSlot.time;
};
</script>

<style lang="scss">
.no-slots-available {
  text-align: end;
}
</style>

<style lang="scss" scoped>
.time-slot-picker {
  height: max-content;
  display: grid;
  place-items: center;
  row-gap: 4px;
  &.slot-selected {
    grid-template-columns: repeat(4, 1fr);
  }
  &.slot-selected.time-slot-picker--from-to {
    grid-template-columns: repeat(2, 1fr);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
