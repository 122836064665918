<template lang="pug">
FeedbackView(:loading="loading" backgroundClass="bg-warning" @close-page="onClosePage" :image="getSaletypeImage(cart?.saletype || Saletype.DELIVERY)")
  template(v-slot:first-container)
    div.text-center
      div.title.mb-2 {{ $t('payment.pending.title') }}
      div.mt-10 {{ $t("payment.pending.wait") }}
      div.mt-3 {{ $t("payment.pending.paymentCanceled") }}
      eat-btn.mt-15.mb-10.bg-white(:to="{ name: 'cart' }") {{ $t('payment.pending.backToCart') }}
</template>

<script setup lang="ts">
import { get as getOrder, getUnauthenticated as getOrderUnauthenticated } from "@/api/Order";
import FeedbackView from "@/layout/FeedbackView.vue";
import { setAppTitle } from "@/store/appTitle";
import type { NullRef } from "@/utils";
import { onMounted, onUnmounted, ref } from "vue";
import type { Order } from "@/models/Order";
import { getQueryParam } from "@/utils/queryParams";
import { useRestoreOrderCart } from "@/store/orderHistory/restoreOrderCart";
import { useMenu } from "@/store/menu";
import { useCart } from "@/store/cart";
import { initData } from "@/store/init/initData";
import { CartPrefix } from "@/models/Cart";
import { useI18n } from "vue-i18n";
import type { Auth } from "@/plugins/auth";
import { inject } from "vue";
import { useRouter } from "vue-router";
import type { Ref } from "vue";
import { useApp } from "@/store/app";
import { Saletype } from "@/models/App";

const i18n = useI18n();
const auth = inject('auth') as Ref<Auth>;
setAppTitle(i18n.t("payment.pending.appTitle").toString());

const router = useRouter();
const loading = ref(false);
const orderId = ref("");
const order: NullRef<Order> = ref(null);
const timeouts = new Set<number>();

const { getSaletypeImage } = useApp();
const { restoreCartItemsFromOrderItems } = useRestoreOrderCart();
const { cart, clearCart, cartPrefix, initCart } = useCart();

const restoreSession = async () => {
  const items = order.value?.orderItems;
  const saletype = order.value?.saleType;
  const { categories, fetchMenu } = useMenu(saletype);
  await fetchMenu();

  if (items && saletype && cartPrefix.value) {
    if (cart.value) clearCart();
    initCart({ saletype, prefix: cartPrefix.value });
    await initData(saletype, auth.value);

    if (categories.value) {
      restoreCartItemsFromOrderItems(items, categories.value);
    }
  }
};

const stopCheckForPaymentTimeouts = () => {
  for (const timeout of timeouts) {
    timeouts.delete(timeout);
    clearTimeout(timeout);
  }
};

const checkForPayment = async () => {
  if (cart.value?.prefix === CartPrefix.RESTAURANT || auth.value.check()) {
    order.value = await getOrder(orderId.value);
  } else {
    order.value = await getOrderUnauthenticated(orderId.value);
  }

  if (order.value) {
    await restoreSession();
    if (order.value.hasBeenPaid) {
      clearCart();
      router.push({ name: "payment.success", query: { orderId: orderId.value } });
    }
  }
};

const startCheckForPaymentTimeouts = () => {
  const timeoutPeriodMs = 5000;
  const maxProcessingDuration = 1000 * 60 * 5;
  const numberOfTimeouts = maxProcessingDuration / timeoutPeriodMs;
  for (let i = 0; i < numberOfTimeouts; i++) {
    const timeout = setTimeout(checkForPayment, timeoutPeriodMs * i) as unknown as number;
    timeouts.add(timeout);
  }
};

onMounted(async () => {
  loading.value = true;
  orderId.value = getQueryParam("orderId");

  if (!auth.value.check()) {
    const userToken = getQueryParam("token");
    if (userToken) auth.value.setUserToken(userToken);
  }

  if (!orderId.value) {
    router.replace({ name: "cart" });
    return;
  }

  startCheckForPaymentTimeouts();

  loading.value = false;
});

onUnmounted(() => {
  stopCheckForPaymentTimeouts();
});

const onClosePage = () => {
  router.replace({ name: "cart.checkout" });
};
</script>
