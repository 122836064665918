<template lang="pug">
div
  template(v-if="!moneyTypes.length")
    EatSelector.d-flex.flex-column.gap-2.mt-6(v-model="localValue" :options="paymentMethods")
      template(v-slot="{ option, selected }")
        EatToggleButton.w-100.px-5.rounded-lg.border(v-if="auth.check() || option !== PaymentMethod.FIDELITY_CARD" :class="{ 'border-clr-primary bg-selected': selected }")
          div.text-h6.font-weight-regular.me-auto.text-left {{ $t(getPaymentMethodTranslationKey(option)) }}
          EatIcon.flex-shrink-0(:icon="getPaymentIcon(option)")
        RouterLink.w-100.d-flex.align-center.px-5.rounded-lg.border(v-else :to="{ name: 'auth.login' }")
          div.text-h6.font-weight-regular.me-auto.text-left {{ $t(getPaymentMethodTranslationKey(option)) }}
          EatIcon.flex-shrink-0(:icon="getPaymentIcon(option)")

  template(v-else)
    EatBtnGroup.mt-6(:modelValue="buttonGroupValue" @update:modelValue="onButtonGroupChange" :options="buttonGroupOptions")
    EatSelector.d-flex.flex-column.gap-2.mt-6(v-if="!isPayAtTheCashier" v-model="localValue" :options="paymentMethods")
      template(v-slot="{ option, selected }")
        EatToggleButton.w-100.px-5.rounded-lg.border(v-if="auth.check() || option !== PaymentMethod.FIDELITY_CARD" :class="{ 'border-clr-primary bg-selected': selected }")
          div.text-h6.font-weight-regular.me-auto.text-left {{ $t(getPaymentMethodTranslationKey(option)) }}
          EatIcon.flex-shrink-0(:icon="getPaymentIcon(option)")
        RouterLink.w-100.d-flex.align-center.px-5.rounded-lg.border(v-else :to="{ name: 'auth.login' }")
          div.text-h6.font-weight-regular.me-auto.text-left {{ $t(getPaymentMethodTranslationKey(option)) }}
          EatIcon.flex-shrink-0(:icon="getPaymentIcon(option)")
    template(v-else)
      div.text-center.py-3.text-caption {{ $t('checkout.chooseDeliveryMoneyType') }}:
      EatSelector.d-flex.flex-column.gap-2(:modelValue="localValue" :options="moneyTypes")
        template(v-slot="{ option, selected }")
          EatToggleButton.w-100.px-5.rounded-lg.border(:class="{ 'border-clr-primary bg-selected': selected }" @click="localValue = option.value")
            div.text-h6.font-weight-regular.me-auto.text-left {{ option.label }}
</template>

<script setup lang="ts">
import EatBtnGroup from "@/components/buttons/EatBtnGroup.vue";
import { PaymentMethod, Saletype } from '@/models/App';
import type { Auth } from '@/plugins/auth';
import { useApp } from '@/store/app';
import { useCart } from "@/store/cart";
import { computed, inject, onMounted, ref, type Ref } from 'vue';
import { useI18n } from "vue-i18n";

const { 
  hasPayAtTheCashier, 
  hasOnlyPayAtTheCashier, 
  paymentMethods, 
  getPaymentMethodTranslationKey, 
  getPaymentIcon,
  moneyTypes
} = useApp();
const auth = inject('auth') as Ref<Auth>;
const i18n = useI18n();
const { cart } = useCart();

const props = defineProps({
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);
const buttonGroupValue = ref();
const isPayAtTheCashier = computed(() => localValue.value?.startsWith(PaymentMethod.CASH_ON_DELIVERY));
const buttonGroupOptions = computed(() =>{ 
  const options = [{ 
    label: cart.value?.saletype === Saletype.DELIVERY ? 'checkout.cashOnDelivery' : 'checkout.payAtTheCashier', 
    value: PaymentMethod.CASH_ON_DELIVERY
  }] as { label: string, value: string }[];
  if(!hasOnlyPayAtTheCashier.value) options.unshift({ label: 'checkout.payOnline', value: 'online' });
  return options;
});
const onButtonGroupChange = (value: string) => {
  buttonGroupValue.value = value;
  if(value === PaymentMethod.CASH_ON_DELIVERY) localValue.value = moneyTypes.value[0].value;
  else localValue.value = paymentMethods.value[0];
};

const localValue = computed({
  get: () => props.modelValue,
  set: (value: string | undefined) => emit('update:modelValue', value)
});

onMounted(() => {
  if(hasOnlyPayAtTheCashier.value && moneyTypes.value.length) {
    buttonGroupValue.value = PaymentMethod.CASH_ON_DELIVERY;
    localValue.value = moneyTypes.value[0].value;
  } else {
    buttonGroupValue.value = 'online';
    localValue.value = paymentMethods.value[0];
  }
})
</script>