<template lang="pug">
EatView.bg-neutral-a(:loading="viewLoading" :isScrollable="true")
  template(v-slot:default)
    div.bg-neutral-a(v-if="cart")
      div.mt-3.bg-white.container

        div.pb-3
          h6.text-h6.pb-4 {{ $t("order.saletypeSection.pickUpMethod") }}
          div.w-max-content.border.py-1.px-7.bg-controls.font-weight-medium.rounded-sm {{ $t(`general.saletype.${selectedSaletype}`) }}

        div.selectors(v-if="cartPrefix === CartPrefix.RESTAURANT")
          div.time-slot-selector__container.py-3(v-if="cart.orderTimeSlot")
            h6.text-h6.pb-2 {{ $t("order.saletypeSection.date") }}
            EatField(name="dateTimeSlot" v-slot="{ value, errored }")
              DateTimeSlotButton(:modelValue="value" @update:modelValue="onSubmitDateTimeSlot" :errored="errored")
              EatFieldMessage(name="dateTimeSlot")

          div.address-selector__container.py-3
            h6.text-h6.pb-2 {{ cart.saletype === Saletype.DELIVERY ? $t("order.saletypeSection.deliveryAddress") : $t("order.saletypeSection.takeawayAddress") }}
            transition(name="fade" mode="out-in")
              a.location-address__container.d-flex.align-center.gap-2.border.rounded-sm.py-2.px-4.bg-primary(v-if="selectedSaletype === Saletype.TAKEAWAY" key="1" :href="gmapsLink" target="_blank")
                div.me-auto.py-2
                  span {{ $t("order.saletypeSection.takeawayMessagge") }}
                  span.font-weight-medium {{ location ? ` ${location.address}, ${location.city}` : '' }}
                EatIcon(icon="$directions").flex-shrink-0 

              div.delivery-address__container.border.rounded-sm.py-2.px-4.d-flex.align-center(v-else-if="selectedSaletype === Saletype.DELIVERY" key="2")
                router-link.w-100.text-black.d-flex.align-center.gap-2(v-if="cart && cart.deliveryAddress" :to="{ name: 'order.deliveryAddress' }")
                  EatIcon(icon="$point").text-primary  
                  AddressFormatted.px-3(:address="cart.deliveryAddress")
                  EatIcon.ms-auto.flex-shrink-0(icon="$chevronDown" :size="18").fake-drop-down__btn
                router-link.w-100.text-primary.d-flex.align-center.gap-2(v-else :to="{ name: 'order.deliveryAddress' }")
                  EatIcon(icon="$pointPlus").text-primary
                  span.px-3 {{ $t("order.saletypeSection.addDeliveryAddress") }}
                  EatIcon.ms-auto.flex-shrink-0(icon="$chevronDown" :size="18").fake-drop-down__btn

        div.py-3  
          h6.text-h6.pb-4 Info
          template(
            v-if="selectedSaletype === Saletype.TAKEAWAY_ON_SITE && customFields?.length" 
            v-for="field, i in customFields" :key="i"
          )
            EatField(v-if="!field.isHidden" :name="`customFields.${field.name}`" v-slot="{ value, handleChange }")
              EatTextInput(v-if="field.type === 'text'" :modelValue="value" @update:modelValue="handleChange" icon="$pencil" :placeholder="field.label")
              EatNumberInput.border.rounded-xl.px-4.py-3(v-if="field.type === 'number'" :modelValue="value" @update:modelValue="handleChange" icon="$pencil") {{ field.label }}
              EatFieldMessage(:name="`customFields.${field.name}`" :hint="field.isMandatory ? 'form.hint.required' : ''")

          template(v-else)
            EatField(name="name" v-slot="{ value, handleChange }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" icon="$people" placeholder="form.name.placeholder")
              EatFieldMessage(name="name" hint="form.hint.required")

            EatField.mb-8(name="covers" v-slot="{ value, handleChange }")
              EatNumberInput.border.rounded-xl.px-4.py-3(:modelValue="value" @update:modelValue="handleChange" :minValue="1" icon="$people") {{ $t('checkout.form.covers') }}
          
          EatField.mb-8(name="cutlery" v-slot="{ value, handleChange }" v-if="askCutlery")
            EatToggleButton.w-100.pa-4.gap-4.rounded.border(:modelValue="value" @update:modelValue="handleChange" icon="$cutlery" :class="value ? 'border-clr-primary' : ''")
              div {{ $t('checkout.form.cutlery') }}
              EatToggleIcon.ms-auto(:on="value" :class="value ? 'text-primary' : 'text-muted'" onIcon="$check" offIcon="$plus" :size="18")

          EatField.mb-8(name="note" v-slot="{ value, handleChange }")
            EatTextArea(:modelValue="value" @update:modelValue="handleChange" rows="2" placeholder="form.note.placeholder")

        div.billing-information__container.pt-6
          EatField.d-flex.align-center.pb-4(name="invoiceData.isInvoiceRequested" v-slot="{ value, handleChange }")
            h6.text-h6.me-auto {{ value ? $t("checkout.invoiceData.title") : $t("checkout.invoiceData.requestInvoice") }}
            EatCheckbox(:modelValue="value" @update:modelValue="handleChange")
          div.billing-information__form(v-if="values.invoiceData?.isInvoiceRequested")
            EatField.pb-6(name="invoiceData.noVatCompany" v-slot="{ value, handleChange }")
              EatCheckbox(:modelValue="value" @update:modelValue="handleChange" ) {{ $t("checkout.invoiceData.noVatCompany") }}
            EatField(v-if="!values.invoiceData?.noVatCompany" name="invoiceData.vatCode" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.vatCode.placeholder")
              EatFieldMessage(name="invoiceData.vatCode" :hint="values.invoiceData?.fiscalCode || values.invoiceData?.vatCode ? '' : 'form.hint.required'")
            EatField(name="invoiceData.fiscalCode" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.fiscalCode.placeholder")
              EatFieldMessage(name="invoiceData.fiscalCode" :hint="values.invoiceData?.vatCode || values.invoiceData?.fiscalCode ? '' : 'form.hint.required'")
            EatField(name="invoiceData.businessName" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.businessName.placeholder")
              EatFieldMessage(name="invoiceData.businessName" hint="form.hint.required")
            EatField(name="invoiceData.email" v-slot="{ value, handleChange, errored }")
              EatTextInput(type="email" :modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.email.placeholder")
              EatFieldMessage(name="invoiceData.email" hint="form.hint.required")
            EatField(name="invoiceData.address" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.address.placeholder")
              EatFieldMessage(name="invoiceData.address" hint="form.hint.required")
            EatField.mb-8(name="invoiceData.streetNumber" v-slot="{ value, handleChange }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" placeholder="form.address.streetNumber.placeholder")
            EatField(name="invoiceData.city" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.city.placeholder")
              EatFieldMessage(name="invoiceData.city" hint="form.hint.required")
            EatField(name="invoiceData.zipCode" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.zipCode.placeholder")
              EatFieldMessage(name="invoiceData.zipCode" hint="form.hint.required")
            EatField(name="invoiceData.province" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.province.placeholder")
              EatFieldMessage(name="invoiceData.province" hint="form.hint.required")
            EatField.mb-8(name="invoiceData.nation" v-slot="{ value, handleChange }")
              EatSelect(:options="nations" :modelValue="value" @update:modelValue="handleChange" placeholder="form.address.nation.placeholder")
            EatField(name="invoiceData.invoicingEndpoint" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.invoicingEndpoint.placeholder")
              EatFieldMessage(name="invoiceData.invoicingEndpoint" :hint="values.invoiceData?.invoicingEndpoint || values.invoiceData?.pec ? '' : 'form.hint.required'")
            EatField(name="invoiceData.pec" v-slot="{ value, handleChange, errored }")
              EatTextInput(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.address.pec.placeholder")
              EatFieldMessage(name="invoiceData.pec" :hint="values.invoiceData?.pec || values.invoiceData?.invoicingEndpoint ? '' : 'form.hint.required'")
                                            
        
        EatField.mt-15(name="paymentMethod" v-slot="{ value, handleChange, errored }")
          div.text-h6.mt-6 {{ $t('checkout.payment') }}
          EatFieldMessage(hint="form.hint.required" :errorMessage="errored ? 'checkout.choosePaymentMethod' : ''")
          PaymentMethodsSelector(:modelValue="value" @update:modelValue="handleChange")
            
        div.mt-15.py-3.d-flex.flex-column
          h6.text-h6.pb-6.me-auto {{ $t('checkout.discounts') }}
          div(v-if="isAuthenticationEnabled && user && user.fidelityCard && user.fidelityCard.affiliation")
            Loading.text-primary.mb-7.mt-2(v-if="isLoadingMembership" :size="42")
            EatToggleButton.w-100.pa-4.gap-4.rounded.border.mb-8(v-else :modelValue="!!cart.affiliationDiscount" @update:modelValue="toggleMembershipDiscount" icon="$fidelity" :class="!!cart.affiliationDiscount ? 'border-clr-primary' : ''")
              div.text-left {{ cart.affiliationDiscount ? user.fidelityCard.affiliation.toUpperCase() : $t('checkout.discounts.applyMembershipDiscount').toUpperCase() }}
              EatToggleIcon.ms-auto(:on="!!cart.affiliationDiscount" :class="!!cart.affiliationDiscount ? 'text-primary' : 'text-muted'" :size="18" onIcon="$check" offIcon="$plus")
          div.d-flex.align-center
            div.flex-grow-1.flex-shrink-1
              EatTextInput.flex-shrink-1.d-flex(
                :modelValue="promoCode"
                @update:modelValue="updatePromoCode"
                :placeholder="$t(`checkout.discounts.placeholder`)"
                @keyup.enter="onPromoCodeSubmit"
                icon="$promotion"
              )   
              EatFieldMessage(:errorMessage="error")
            EatBtn.flex-shrink-0.text-primary.border.border-clr-primary.mb-8.ms-4(type="button" @click="onPromoCodeSubmit" :loading="isLoadingPromoCode") {{ $t(`checkout.discounts.apply`) }}
          div.d-flex.justify-space-between(v-for="promoCode, i in cart.promoCodes")
            div.promo-code__confirmed-code.pa-4 {{ promoCode.code }}
            EatIconBtn.mx-4.text-error(type="button" icon="$trash" @click="removePromoCodeById(promoCode.promoCodeId)")
          router-link.text-center(:to="{name: 'user.promoCodes'}") 
            span.mx-auto {{ $t("checkout.discounts.viewActivePromotions")  }}

      EatUl
        EatLi.d-flex
          div.me-auto {{ $t("checkout.order") }}
          div.font-weight-bold {{ formatPrice(cart.subTotal) }}
        
        EatLi(v-if="cart.subtotalVariation")
          div.d-flex.align-center
            div.me-auto(v-if="cart.subtotalVariation.promoCode") {{ cart.subtotalVariation.promoCode?.code || '' }}
            SubTotalPrice(:isPercentage="cart.subtotalVariation.isPercentage" :variation="cart.subtotalVariation.variation" :priceVariation="cart.subtotalVariation.priceVariation")
        EatLi(v-if="cart.affiliationDiscount")
          div.d-flex.align-center
            div.me-auto.text-uppercase {{ cart.affiliationDiscount.name }}
            Price(:price="cart.affiliationDiscount.price")

        EatLi.d-flex(v-if="selectedSaletype == Saletype.DELIVERY && deliveryPrice")
          div.me-auto {{ $t('general.deliveryPrice') }}
          div(v-if="deliveryPrice.isPercentage") {{ deliveryPrice.price }}%
          div(v-if="!deliveryPrice.isPercentage") + {{ formatPrice(deliveryPrice.price) }}

      div.text-caption.text--disabled.pb-8.mb-16.pt-4.px-5.text-center(v-if="app") {{ $t("checkout.termsOfSales") }} 
        a(:href="app.urlTermsOfSales" @click.stop) {{ $t('checkout.termsOfSalesLink') }}      

  template(v-slot:cta)
    div.bg-white.d-flex.align-center.pa-3.border-t
      EatFloatBtn(type="button" @click="onSubmit" :loading="submittingCheckout" :disabled="submittingCheckout") {{ values.paymentMethod === PaymentMethod.CASH_ON_DELIVERY ? $t("checkout.action.sendOrder") : $t("checkout.action.pay") }}
      div.ms-auto.text-h6.font-weight-regular {{ $t("checkout.total", { price: formatPrice(cart?.total || 0) }) }}
</template>

<script setup lang="ts">
import DateTimeSlotButton from "@/components/dateTimeSlots/DateTimeSlotButton.vue";
import PaymentMethodsSelector from "@/views/cart/components/PaymentMethodsSelector.vue";
import SubTotalPrice from "@/components/utils/SubTotalPrice.vue";
import Price from "@/components/utils/Price.vue";
import { PaymentMethod, Saletype } from "@/models/App";
import { CartPrefix } from "@/models/Cart";
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { formatPrice } from "@/utils/formatPrice";
import { computed, onMounted, ref } from "vue";
import { type DateTimeSlot } from "@/store/timeSlots/dateTimeSlots";
import { useTimeSlots } from "@/store/timeSlots/timeSlots";
import { useCheckout } from "@/store/cart/checkout";
import { shortAddress } from "@/models/AppLocation";
import { useCart } from "@/store/cart";
import { useOrderSaletype } from "@/store/order/saletype";
import { useUser } from "@/store/user";
import countries from "@/assets/countries.json";
import type { APIError } from "@/api/API";
import { useCartItems } from "@/store/cart/cartItems";
import { useMembershipDiscount } from "@/store/cart/affiliationDiscount";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import type { Ref } from "vue";
import { useForm } from "vee-validate";
import { string, object, boolean, number, Schema } from 'yup';
import { usePromoCode } from "@/store/cart/promoCode";

const i18n = useI18n();
const auth = inject('auth') as Ref<Auth>;
setAppTitle(i18n.t("checkout.title").toString());
const viewLoading = ref(false);
const router = useRouter();
const { user, refreshUser } = useUser();
const { cart, cartPrefix, setCartOrderTimeSlot, setCartMembershipDiscount } = useCart();
const { selectedSaletype } = useOrderSaletype();
const { app, location, customFields, paymentMethods, getPaymentIcon, getPaymentMethodTranslationKey, isAuthenticationEnabled } = useApp();
const { availableSlots, isStillAvailable } = useTimeSlots();
const { askCutlery, checkoutOrder, submittingCheckout } = useCheckout();
const { removePromoCodeById } = useCartItems();
const { applyMembershipDiscountToCart } = useMembershipDiscount();

const deliveryPrice = computed(() => cart.value?.deliveryPrice);

const optionalFieldsValidationObject = {} as Record<string, Schema>;
if(cartPrefix.value === CartPrefix.RESTAURANT) {
  optionalFieldsValidationObject.dateTimeSlot = object().test('is_slot_valid', '', function(value, ctx) {
    const dateTimeSlot = value as DateTimeSlot;
    const isValid = dateTimeSlot.timeSlot && (isStillAvailable(dateTimeSlot.timeSlot, availableSlots.value));
    if(isValid) return true;
    else return ctx.createError({ message: () => ({ key: 'form.errors.dateTimeSlot.invalid' }) });
  }).required().label('form.dateTimeSlot.fieldName');
}
if(cartPrefix.value !== CartPrefix.TAKEAWAY_ON_SITE || (cartPrefix.value === CartPrefix.TAKEAWAY_ON_SITE && !customFields.value?.length)) {
  optionalFieldsValidationObject.name = string().required().label('form.name.fieldName');
}
if(cartPrefix.value === CartPrefix.TAKEAWAY_ON_SITE && customFields.value?.length) {
  optionalFieldsValidationObject.customFields = object(
    customFields.value.reduce((shape, field) => {
      if(field.type === 'text') shape[field.name] = string().label(field.label);
      if(field.type === 'number') shape[field.name] = number().label(field.label);
      if(field.isMandatory && shape[field.name]) shape[field.name] = shape[field.name].required();
      return shape;
    }, {} as Record<string, Schema>)
  );
}

const validationSchema = object({
    ...optionalFieldsValidationObject,
    paymentMethod: string().required(),
    invoiceData: object({
      isInvoiceRequested: boolean(),
      vatCode: string()
        .test("required_if", '', function(value, ctx) {
          if(!this.parent.isInvoiceRequested || this.parent.fiscalCode || !!value) return true;
          else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.required', params: { label }}) });
        }).label('form.address.vatCode.fieldName'),
      fiscalCode: string()
        .test("required_if", '', function(value, ctx) {
          const length = this.parent.noVatCompany ? 11 : 16;
          if(this.parent.isInvoiceRequested && !value && !this.parent.vatCode) 
            return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.required', params: { label }}) });
          if(value && value.length !== length) 
            return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.string.length', params: { label, length }}) });
          return true;
        }).label('form.address.fiscalCode.fieldName'),
      businessName: string()
        .when(['isInvoiceRequested'], ([value], schema) => value ? schema.required() : schema)
        .label('form.address.businessName.fieldName'),
      email: string().email()
        .when(['isInvoiceRequested'], ([value], schema) => value ? schema.required() : schema)
        .label('form.email.fieldName'),
      address: string()
        .when(['isInvoiceRequested'], ([value], schema) => value ? schema.required() : schema)
        .label('form.address.address.fieldName'),
      city: string()
        .when(['isInvoiceRequested'], ([value], schema) => value ? schema.required() : schema)
        .label('form.address.city.fieldName'),
      zipCode: string().length(5)
        .when(['isInvoiceRequested'], ([value], schema) => value ? schema.required() : schema)
        .label('form.address.zipCode.fieldName'),
      province: string().length(2)
        .when(['isInvoiceRequested'], ([value], schema) => value ? schema.required() : schema)
        .label('form.address.province.fieldName'),
      invoicingEndpoint: string()
        .test("required_if", '', function(value, ctx) {
          if(!this.parent.isInvoiceRequested || this.parent.pec || value) return true;
          else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.required', params: { label }}) });
        }).label('form.address.invoicingEndpoint.fieldName'),
      pec: string().email()
        .test("required_if", '', function(value, ctx) {
          if(!this.parent.isInvoiceRequested || this.parent.invoicingEndpoint || value) return true;
          else return ctx.createError({ message: ({ label }) => ({ key: 'form.errors.required', params: { label }}) });
        }).label('form.address.pec.fieldName'),
    })
  });

const { values, setFieldValue, handleSubmit } = useForm({
  validateOnMount: true,
  validationSchema,
});

const gmapsLink = computed(() => {
  if (!location.value) return "";
  return `https://www.google.com/maps/search/?api=1&query=${
    location.value.businessName
  },${shortAddress(location.value)}`;
});

const nations = computed(() => {
  return [...countries].map(country => ({
    value: country.code,
    label: country.code === "IT" ? "Italia" : country.name
  }));
});

onMounted(async () => {
  viewLoading.value = true;
  if (!cart.value?.items.length) router.push({ name: "order" });

  try {
    if (!user.value && auth.value.check()) await refreshUser();
    if (user.value) {
      if(cartPrefix.value !== CartPrefix.TAKEAWAY_ON_SITE || !customFields.value?.length) {
        setFieldValue('name', `${user.value.name} ${user.value.surname}`);
      }
      if (!user.value?.phones?.length) {
        router.replace({ name: "user.editProfile", query: { phone: "true", completeProfile: "true" }});
      }
    }
  
  } finally {
    viewLoading.value = false;
  }
  setFieldValue('dateTimeSlot', cart.value?.orderTimeSlot);
  for(const field of (customFields.value || [])) {
    if(field.type === 'number') setFieldValue(`customFields.${field.name}`, Number(field.default));
    if(field.type === 'text') setFieldValue(`customFields.${field.name}`, String(field.default));
  }

});

const onSubmitDateTimeSlot = (dateTimeSlot: DateTimeSlot) => {
  if (dateTimeSlot) {
    setCartOrderTimeSlot(dateTimeSlot);
    setFieldValue('dateTimeSlot', dateTimeSlot);
  }
};

const isLoadingMembership = ref(false);
const toggleMembershipDiscount = async () => {
  try {
    isLoadingMembership.value = true;
    if (cart.value?.affiliationDiscount) setCartMembershipDiscount(null);
    else await applyMembershipDiscountToCart(values);
  } finally {
    isLoadingMembership.value = false;
  }
};

const { applyPromoCodeToCart } = usePromoCode();
const promoCode = ref("");
const error = ref("");
const isLoadingPromoCode = ref(false);

const updatePromoCode = (value: string) => {
  if (value) error.value = "";
  promoCode.value = value;
};

const onPromoCodeSubmit = async () => {
  const { cartPrefix, cart } = useCart();
  if (promoCode.value == "") {
    error.value = i18n.t("checkout.discounts.missed").toString();
    setSnackbar(i18n.t("checkout.discounts.missed").toString());
  } else if (
    cartPrefix.value === CartPrefix.RESTAURANT &&
    !cart.value?.orderTimeSlot?.timeSlot
  ) {
    error.value = i18n.t("checkout.discounts.missedTime").toString();
  } else if (!isLoadingPromoCode.value) {
    try {
      isLoadingPromoCode.value = true;
      await applyPromoCodeToCart(
        values,
        promoCode.value,
        cartPrefix.value === CartPrefix.RESTAURANT || auth.value.check()
      );
    } finally {
      promoCode.value = "";
      isLoadingPromoCode.value = false;
    }
  }
};

const onValidSubmit = async (values: any) => {
  try {
    const authenticated = cartPrefix.value === CartPrefix.RESTAURANT || auth.value.check();
    await checkoutOrder(values, authenticated, router);
  } catch (e) {
    const error = e as APIError;
    if (error?.response?.status === 400) {
      i18n.t("checkout.snackBar.mandatoryFieldsMissing");
    } else {
      throw error;
    }
  }
};

const onInvalidSubmit = (e: any) => {
  setSnackbar(i18n.t("checkout.snackBar.mandatoryFieldsMissing"), TypeSnackbar.WARNING);
};

const onSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);
</script>

<style lang="scss" scoped>
.saletype {
  width: max-content;
}

.delivery-address__container,
.location-address__container {
  min-height: 80px;
}

.location-address__container {
  text-decoration: none;
}

.fake-drop-down__btn {
  text-decoration: none;
  margin-left: auto;
  display: block;
}

.btn--google-maps {
  min-width: 121px;
}
</style>
