import { Saletype } from "@/models/App";
import { computed, ref, type Ref } from "vue";
import { useShowMoreDialog } from "../layout/moreDialog";
import { useCart } from "../cart";

const mayBeNewSaletype: Ref<Saletype | null> = ref(null);
const showClearCartPrompt = useShowMoreDialog();

export function useOrderSaletype() {
  const {
    cartPrefix,
    cart,
    clearCart,
    initRestaurantCart,
    setInitialCartDeliveryAddress
  } = useCart();

  const selectedSaletype = computed(() => {
    return cart.value?.saletype;
  });

  const onSaletypeChange = async (saletype: Saletype) => {
    if (saletype === cart.value?.saletype) return;

    if (cart.value && cart.value.items.length) {
      showClearCartPrompt.value = true;
      mayBeNewSaletype.value = saletype;
    } else {
      clearCart();
      await initRestaurantCart(saletype);

      if (saletype === Saletype.DELIVERY) {
        setInitialCartDeliveryAddress();
      }
    }
  };

  return {
    mayBeNewSaletype,
    cartPrefix,
    selectedSaletype,
    onSaletypeChange
  };
}
